<!-- 通用弹出框 -->
<!--
titleText 标题
visible 显示隐藏， true|false, 默认false
size 尺寸，full|biglarge|large|big|middle|small，默认big， big：830*560， middle: 700*400, small:420*220, large: 1000*auto, biglarge: 1200*auto, full: 全屏:100vw
fullscreen 是否全屏
destroyCtrl 关闭时销毁，默认true
@close，@cancel 点击关闭触发事件
@OK 点击确认触发事件
textCancel  取消按钮文字
textOk      确定按钮文字
modal       是否有遮罩  默认为true  false时没有
showClose   是否显示右上角关闭按钮
isHideTitle 是否隐藏title
btnAlign    操作按钮对齐方式： left, center, right
-->
<template>
    <el-dialog
        :title="titleText"
        class="common-dialog"
        :class="[size?'common-dialog-' + size:'common-dialog-big', isHideTitle?'isHideTitle':'']"
        append-to-body
        :close-on-click-modal="false"
        :show-close="showClose"
        :modal="modal"
        :fullscreen="fullscreen"
        @close="close"
        v-if="destroyCtrl"
        :visible.sync="visibleCtrl"
    >
        <slot name="title" slot="title"></slot>
        <slot></slot>
        <div slot="footer" class="common-dialog-footer" :style="{textAlign: btnAlign}" v-if="showCancel || showOK">
            <el-button @click="close" v-if="showCancel">{{ textCancel }}</el-button>
            <el-button type="primary" v-if="showOK" @click="OK">{{ textOk }}</el-button>
        </div>
    </el-dialog>
</template>

<script>
	export default {
		props: {
			titleText: { default: '提示' },
			visible: {},
			size: { default: 'big' },
      fullscreen: { default: false },
      showClose: { default: true },
      destroyCtrl: { default: true },
			modal: { default: true },
			showCancel: { default: true },
			showOK: { default: true },
			textCancel: { default: '取消' },
      textOk: { default: '确定' },
      // 是否显示标题栏
      isHideTitle: { default: false },
      btnAlign: { default: 'center' },
		},
		components: {},
		data() {
			return {
				visibleCtrl: false
			}
		},
		computed: {
		},
		watch: {
			visible(data) {
				this.visibleCtrl = data
				if (!this.destroyCtrl && !data) this.$emit('update:destroyCtrl', false)
			}
		},
		methods: {
			close() {
				this.$emit('update:visible', false)
        this.$emit('close', false)
        this.$emit('cancel', false)
			},
			OK() {
				this.$emit('OK', true)
			}
		},
		mounted() {
      this.visibleCtrl = this.visible
		}
	}
</script>

<style lang='less'>
@import url('../../less/var.less');
.common-dialog{
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 0;
  .TMS {
    .el-input, .selectwrapper {
      width: 193px;
    }
    .el-pagination .el-select .el-input{ width: 100px;}
    .el-pagination__editor.el-input{width: 50px;}
  }
  .el-dialog{
    padding-left: 16px !important;
    padding-right: 16px;
    width: 1000px;
    margin: 0 !important;
    border-radius: 15px;
    flex-shrink: 0;
    .el-dialog__header{
      padding: 14px 20px 10px 0 !important;
      border-bottom: 1px solid @mainBorderColor;

      .el-dialog__headerbtn {
        top: 15px;
        right: 15px;
      }
    }
    .el-dialog__title{
      font-size: 16px;
      color: @mainTextColor;
    }
    .el-dialog__body{
      min-height: 80px;
      // max-height: 75vh;
      max-height: 65vh;
      overflow-y: auto;
      padding: 20px 5px 30px;
      .el-form-item.warningTips{
        .el-form-item__content{
          margin-left: 0 !important;
          p{
            margin: 0;
            text-align: center;
            color: red;
          }
        }
      }
      .el-form-item.formBottomBtns{
        .el-form-item__content{
          margin-left: 0 !important;
          text-align: center;
          .el-button + .el-button{
            margin-left: 60px;
          }
        }
      }
    }
    .el-dialog__footer{
      text-align: center;
      padding-bottom: 30px;
      .el-button{
        letter-spacing: 3px;
      }
      .el-button+.el-button{
        margin-left: 20px;
      }
    }
    .common-dialog-footer{
      text-align: center;
    }
  }
  ::-webkit-scrollbar {
    width: 6px;
  }
  ::-webkit-scrollbar-thumb {
    background: @mainBorderColor;
  }
  ::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 3px rgba(0, 0, 0, 0.2);
  }
}
.common-dialog-small{
  .el-dialog{
    width: 420px;
  }
}
.common-dialog-text {
  .el-dialog{
    width: 560px;
  }
}
.common-dialog-middle{
  .el-dialog{
    width: 700px;
  }
}
.common-dialog-big {
  .el-dialog{
    width: 830px;
  }
}
.common-dialog-biglarge {
  .el-dialog{
    width: 1200px;
  }
}
.common-dialog-code {
  .el-dialog{
    width: 100%;
    margin: 40px!important;
    height: 94vh;
    .el-dialog__body{
      min-height: 80px;
      max-height: 100vh;
      overflow-y: auto;
      padding: 30px 5px;
    }
  }
}
.common-dialog-full {
  .el-dialog{
    width: 100vw;
    border-radius: 0;
    // margin: 40px!important;
    height: 100vh;
    .el-dialog__body{
      min-height: 80px;
      max-height: calc(100vh - 50px);
      overflow-y: auto;
      padding: 30px 5px;
    }
  }
}
.isHideTitle {
  .el-dialog {
    .el-dialog__header {
      display: none;
    }
  }
}
.common-dialog .el-dialog.is-fullscreen{
  width: 100%;
  margin-top: 0 !important;
  border-radius: 0 !important;
  .el-dialog__body{
    max-height: calc(100% - 50px);
  }
}
</style>
