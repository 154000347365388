<template>
  <div class="dialog-swiper-container">
    <div class="div_video">
      <div class="swiper-container swiper_imgs">
        <div class="swiper-wrapper">
          <div
            class="swiper-slide"
            v-for="article in articles"
            :key="article.id"
          >
            <div :class="{ 'show-content': showContent }">
              <div class="video_cover">
                <img :src="article.files" alt="" />
                <p
                  style="
                    position: absolute;
                    right: 0;
                    bottom: 0;
                    color: white;
                    background: rgb(22 24 24 / 60%);
                    padding: 5px 10px 5px 10px;
                  "
                >
                  {{ article.title }}
                </p>
              </div>
              <div v-if="showContent" class="content">
                <h2>{{ article.title }}</h2>
                <div v-html="article.content"></div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="div_icons">
        <div class="swiper-container swiper_icons">
          <div class="swiper-wrapper">
            <div
              class="swiper-slide"
              v-for="article in articles"
              :key="article.id"
            >
              <img :src="article.files" alt="" />
            </div>
          </div>
        </div>
        <div class="direction">
          <span class="s_prev"><i></i></span>
          <span class="s_next"><i></i></span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import portalApi from '../../../../lvya-common/src/api/eos/portal'
import { useAddColumnCount } from '../utils/useAddArticleCount'
export default {
  props: {
    typeId: {
      type: String,
      required: true,
    },
    showContent: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      articles: [],
    }
  },
  methods: {
    async getArticles() {
      // const portalSchoolInfo = JSON.parse(
      //   localStorage.getItem('portalSchoolInfo')
      // )
      let reqData = {
        noSchoolId: true,
        schoolId: '7',
        pageNum: 1,
        pageSize: 999,
        typeId: this.typeId,
        isContent: 'Y',
        applyState: 'Y',
      }
      let resData = await portalApi.getArtsByPage(reqData)
      console.log(resData, 'articles')
      this.articles = resData?.list
    },
  },
  async mounted() {
    if (this.typeId) {
      await this.getArticles()
      useAddColumnCount(this.typeId)
      let activeSlide
      new Swiper('.div_video .swiper_imgs', {
        spaceBetween: 0,
        loop: true,
        navigation: {
          nextEl: '.div_video .s_next',
          prevEl: '.div_video .s_prev',
        },
        on: {
          init: function(swiper) {
            activeSlide = this.activeIndex
          },
          slideChange: function() {
            const preSlide = $('.div_video .swiper-slide').eq(activeSlide)
            preSlide.find('video').trigger('pause')
            activeSlide = this.activeIndex
          },
        },
        thumbs: {
          swiper: {
            el: '.div_video .div_icons .swiper_icons',
            spaceBetween: 10,
            slidesPerView: 5,
            watchSlidesVisibility: true,
          },
          autoScrollOffset: 0,
        },
      })
    }
  },
}
</script>

<style lang="less" scoped>
@import "../style/common.css";
@import "../style/global.css";
.dialog-swiper-container {
  position: relative;
  width: 100%;
  .div_mod {
    position: relative;
  }
  .div_video .swiper_imgs {
    margin-bottom: 0.4rem;
  }
  .div_video .swiper_imgs .video_cover {
    position: relative;
    display: block;
  }
  .div_video .swiper_imgs .video_cover::after {
    content: "";
    display: block;
    width: 2.5rem;
    height: 2.5rem;
    position: absolute;
    background-size: cover;
    top: 50%;
    margin-top: -1.25rem;
    left: 50%;
    margin-left: -1.25rem;
    cursor: pointer;
  }
  .div_video .swiper_imgs .video_box {
    display: none;
    background: #000;
  }
  .div_video .swiper_imgs .video_box video {
    width: 100%;
    height: 100%;
  }
  .div_video .div_icons {
    position: relative;
    padding: 0 1rem;
  }
  .div_video .div_icons .swiper-slide {
    cursor: pointer;
  }
  .div_video .div_icons .swiper-slide::after {
    content: "";
    display: block;
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    background: #000;
    opacity: 0.6;
  }
  .div_video .div_icons .swiper-slide-thumb-active::after {
    content: "";
    display: block;
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    background: #000;
    opacity: 0;
  }
}

.direction span {
  top: 50%;
}
@media screen and (max-width: 640px) {
  .div_icons {
    display: none !important;
  }
  .div_video {
    width: 100%;
    max-width: 600px;
  }
  .show-content {
    flex-direction: column;
    .video_cover {
      width: 100% !important;
      img {
        width: 100%;
        height: auto;
      }
    }
    .content {
      width: 100%;
    }
  }
}

.show-content {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  background: #e1e1e1;
  .video_cover {
    width: 65%;
  }
  .content {
    padding: 10px;
    h2 {
      font-size: 30px;
      color: #333;
      line-height: 100%;
      margin-bottom: 0.1rem;
      margin-top: 0.1rem;
      &::after {
        content: "";
        display: block;
        width: 1rem;
        height: 0.06rem;
        background: #b82e1b;
        margin-top: 0.1rem;
      }
    }
    width: 30%;
  }
}
</style>
